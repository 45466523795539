'use client';
 
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
 
export default function UmamiAnalytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
 
  
  useEffect(() => {
    // Check if umami is available before tracking
    if (typeof window !== 'undefined' && window.umami) {
      const url = `${pathname}${searchParams?.toString() ? '?' + searchParams.toString() : ''}`;
      try {
        window.umami.track(url);
      } catch (error) {
        console.debug('Error tracking page view:', error);
      }
    }
  }, [pathname, searchParams]);
 
  return null;
}